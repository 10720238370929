<script setup lang="ts">
import type { Component } from "vue";
import Filter from "../icons/Filter.vue";

type FilterOption = {
  label: unknown;
  value: any;
};

const props = withDefaults(
  defineProps<{
    baseKey: string;
    icon?: Component;
    name?: string;
    title?: string;
    options: FilterOption[];
    defaults?: any[];
    description?: string;
  }>(),
  {
    icon: Filter,
    title: "Filtra",
  }
);

const selected = defineModel<any[] | null>();
const allSelected = computed(() => selected.value?.length === props.options.length);
</script>

<template>
  <div class="bg-primary-50 p-5 mb-5 last:mb-0" :icon="icon" :title="title">
    <FormField :name="name || baseKey">
      <FormItem>
        <div>
          <div class="flex items-center gap-2">
            <component v-if="icon" :is="icon" class="w-5 h-5 text-primary" />
            <FormLabel class="font-semibold text-xl">{{ title }}</FormLabel>
          </div>
          <FormDescription>{{ description }}</FormDescription>
        </div>

        <Button type="button" variant="link" @click="() => (selected = allSelected ? [] : options.map((o) => o.value))">{{ allSelected ? "Deseleziona tutto" : "Seleziona tutto" }}</Button>

        <FormField v-for="option in options" :key="`${baseKey}-${option.value}`" type="checkbox" :value="option.value" :unchecked-value="false" :name="name || baseKey" v-slot="{ componentField }">
          <FormItem class="flex flex-row items-center space-x-3 space-y-0">
            <FormControl>
              <Checkbox
                v-bind="componentField"
                :native-value="option.value"
                :checked="selected?.includes(option.value)"
                @update:checked="
                  (c) => {
                    if (c) selected = [...(selected || []), option.value];
                    else selected = [...(selected?.filter((t) => t != option.value) || [])];
                  }
                "
              />
            </FormControl>
            <FormLabel class="font-normal text-lg">
              {{ option.label }}
            </FormLabel>
          </FormItem>
        </FormField>
        <FormMessage />
      </FormItem>
    </FormField>
  </div>
</template>
